<template>
  <div class="my-second team bgcolor">
      <Head :title="$t('team.my_team')" :show="true" ></Head>
      <div class="common-box team-box">
          <div class="team-list tree" v-if="show">
               <my-trees :list="tree" @checkHasChildren="getlength" ></my-trees>
              <!-- <div class="team-item" v-for="(item,i) in 10" :key="i">
                  <img class="team-item-left"  src="../../../assets/images/tou-qrcode.png" />
                  <div class="team-item-right">
                      <div class="team-item-right-top">
                          <span class="">757884</span>
                          <i>杰卡斯</i>
                          <p>{{$t('mine.zhixing')}}</p>
                      </div>
                      <div class="team-item-right-bottom">
                          <span >加入时间:</span>
                          <span>12:00:00 10/20/2020</span>
                      </div>
                  </div>
              </div>
              <div class="team-item">
                  <img class="team-item-left"  src="../../../assets/images/tou-qrcode.png" />
                  <div class="team-item-right">
                      <div class="team-item-right-top">
                          <span class="">757884</span>
                          <i>杰卡斯</i>
                          <p>{{$t('mine.zhixing')}}</p>
                      </div>
                      <div class="team-item-right-bottom">
                          <span >加入时间:</span>
                          <span>12:00:00 10/20/2020</span>
                      </div>
                  </div>
              </div>
              <div class="team-item">
                  <img class="team-item-left"  src="../../../assets/images/tou-qrcode.png" />
                  <div class="team-item-right">
                      <div class="team-item-right-top">
                          <span class="">757884</span>
                          <i>杰卡斯</i>
                          <p>{{$t('mine.zhixing')}}</p>
                      </div>
                      <div class="team-item-right-bottom">
                          <span >加入时间:</span>
                          <span>12:00:00 10/20/2020</span>
                      </div>
                  </div>
              </div> -->
          </div>

          <div v-if="false" class="no-box" >
                <img class="juan-no" src="../../../assets/images/myteam.png" alt="">
                <p class="no-text">{{$t('team.no_team')}}</p>
          </div>
      </div>
  </div>
</template>


<script>
import myTrees from "./tree.vue"
export default {
    data(){
        return {
            list:[],
            tree:[],
            show:false
        }
    },
    components:{
        myTrees
    },
    methods:{
        getlength(data){
            console.log(data)
        },
        async getdata(){
            try {
                this.tree = null;
                let result =await this.$ajax.treeview()
                
                if(result.code==200){
                    
                    let arr = [];
                    let box = [];
                    // for(var i in result.data.data){
                    //     arr.push(i);
                    // }
                    // for (let value of arr) {
                    //     box.push(result.data.tree[value])
                    // }
                     
                    this.tree = result.data.data;
                    this.show = true;
                }
            }catch{

            }
        }
    },
    mounted(){
        this.getdata();
    }
}
</script>
