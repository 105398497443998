<template>
    <ul >
        <li  v-for="(t,i) in list" :key="i" >
             <div class="team-item">
                  <!-- <img class="team-item-left"  v-lazy="t.pVRank.user_headimgurl" /> -->
                  <p class="team-item-index" >{{i}}</p>
                  <div class="team-item-right">
                      <div class="team-item-right-top">
                          <span class="">{{t.pVRank.user_uid}}</span>
                          <!-- <i>{{t.name}}</i> -->
                          <p>{{t.pVRank.rank}}</p>
                      </div>
                      <div class="team-item-right-bottom">
                          <!-- <span >{{$t('jiang.enter_time')}}:</span> -->
                          <span>{{t.name}}</span>
                      </div>
                      <div class="team-item-right-pv">
                          {{t.pVRank.pv_balance | int_format }} PV
                      </div>
                  </div>
              </div>
            <my-trees  v-if="t.children.length>0"  :list="t.children"></my-trees>
        </li>
    </ul>
</template>

<script>
export default {
    name:"myTrees",
    props:['list'],
    data(){
        return {  
            open:true,
            loading:false,
            depth:0
        }
    },
    mounted(){
        this.$emit("checkHasChildren",this.list)
    },
    methods:{
        getlength(data){
            console.log(data)
        }
    },

}
</script>
